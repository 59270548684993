import GameLoginLeft2z from '@/features/recreation/theme/2z/game-login-left'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function GameLoginLeft(props) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameLoginLeft2z {...props} />
    default:
      return <GameLoginLeft2z {...props} />
  }
}
