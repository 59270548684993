import { useState, useRef } from 'react'
import { Button, Form, Input, Message } from '@nbit/arco'
import { useUpdateEffect } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import Link from '@/components/link'
import UserTipsInfo from '@/features/user/common/tips-info'
import UserPasswordValidateTips from '@/features/user/common/password-validate'
import UserPopUp from '@/features/user/components/popup'
import UserPopUpSuccessContent from '@/features/user/components/popup/content/success'
import UserCountDown from '@/features/user/components/count-down'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import { RetrieveRestPasswordRules } from '@/features/user/utils/validate'
import {
  postMemberSafeVerifyEmailSend,
  postMemberSafeVerifyPhoneSend,
  postMemberSafeResetPassword,
  // postMemberQueryWorkOrderStatus,
} from '@/apis/user'
import {
  UserValidateMethodEnum,
  UserSendValidateCodeBusinessTypeEnum,
  UserVerifyTypeEnum,
  UserAgreementEnum,
} from '@/constants/user'
import { useUserStore } from '@/store/user'
import { TabsTypeLogRegisterType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { UserInformationDesensitization, FormValuesTrim } from '@/features/user/utils/common'
import { useLayoutStore } from '@/store/layout'
import { usePageContext } from '@/hooks/use-page-context'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { postAccountCheckRequest } from '@/apis/user'
import { getResetPasswordPath, getRestSafetyicationPath } from '@/helper/route'
import { GetUserTypeEnum } from '@/constants/user'
import { TabsResetPasswordStatusType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { useCustomerServiceLink } from '@/hooks/features/customer-service/index'
import { useLimitSwitch } from '@/hooks/features/limitSwitch'
import { GetUserLimitSwitchEnum } from '@/constants/user'
import GameResetTitle from '@/features/recreation/theme/components/game-reset-title'
import GameResetForm from '@/features/recreation/theme/components/game-reset-form'
import Icon from '@/components/icon'
import styles from './index.module.css'

const FormItem = Form.Item

function UserRetrieveResetPassword() {
  const [successPopup, setSuccessPopup] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [passwordValidate, setPasswordValidate] = useState<boolean>(false)
  const [workOrderPopUp, setWorkOrderPopUp] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showReset, setShowReset] = useState<boolean>(false)
  const pageContext = usePageContext()
  const geeTestInit = useGeeTestBind()
  const { setResetPassword, checkParams } = useUserStore()
  const customerServiceLinks = useCustomerServiceLink()
  // const [disabled, setDisabled] = useState<boolean>(true)
  const [passwordShow, setPasswordShow] = useState({
    newPasswordShow: false,
    confirmPasswordShow: false,
  })
  const isEmailSend = useRef<boolean>(false)
  const isPhoneSend = useRef<boolean>(false)

  const store = useUserStore()
  const layoutStore = useLayoutStore()
  const [form] = Form.useForm()
  const newPassword = Form.useWatch('newPassword', form)
  const confirmPassword = Form.useWatch('confirmPassword', form)
  // const verifyCode = Form.useWatch('verifyCode', form)

  const info = store.userTransitionDatas

  const data = {
    type: UserSendValidateCodeBusinessTypeEnum.resetPassword,
    email: info.email || '',
    mobileCountryCode: info.mobileCountryCode || '',
    mobile: info.mobileNumber || '',
    uid: info.uid || '',
  }

  const passwordRules = RetrieveRestPasswordRules(newPassword, isEmailSend, isPhoneSend)
  const popupLimit = useLimitSwitch()

  const handleClearPassword = (key: string) => {
    form.setFieldValue(key, '')
    key === 'newPassword' && setPassword('')
  }

  const handleSendEmailValidateCode = async () => {
    const res = await postMemberSafeVerifyEmailSend(data)

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isEmailSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleSendPhoneValidateCode = async () => {
    const res = await postMemberSafeVerifyPhoneSend(data)

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isPhoneSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const initializationData = () => {
    store.setRegisterStatus({
      isCode: false,
      type: UserValidateMethodEnum.email,
    })
    store.setResetPasswordStatus({
      isCode: false,
      type: UserValidateMethodEnum.email,
    })
    store.setIsResetPassword(false)
    store.setLogOrRegister(TabsTypeLogRegisterType.log)
  }

  const handleOnSuccess = async () => {
    setSuccessPopup(false)
    await store.removeUserTransitionDatas()
    store.setIsResetPassword(false)
    store.setLogOrRegister(TabsTypeLogRegisterType.log)
    // link('/login')
    // initializationData()
  }
  const onSubmit = async values => {
    if (!passwordValidate) {
      Message.warning(t`features_user_register_flow_index_2448`)
      return
    }

    setResetPassword(values)
    if (info.pwdReset) {
      // 人工重置密码
      /** 极验验证 */
      geeTestInit(geeTestOnSuccess, geeTestOnError)
      return
    }
    const { data } = await postAccountCheckRequest(checkParams)
    if (data) {
      store.setResetPasswordStatus({
        isCode: TabsResetPasswordStatusType.safety,
        type: UserValidateMethodEnum.email,
      })
      return
    }
    setShowReset(true)
  }

  const geeTestOnSuccess = async () => {
    setLoading(false)
    const option = {
      newPassword,
      account: checkParams.account,
      mobileCountryCode: checkParams.mobileCountryCode,
      safeVerifyType: checkParams.accountType,
    }
    const res = await postMemberSafeResetPassword(option)
    res.isOk && res.data?.isSuccess && setSuccessPopup(true)
  }

  const geeTestOnError = () => setLoading(false)

  const handlelRetrieve = () => {
    store.setLogRegisterVisible(false)
    link(getResetPasswordPath(GetUserTypeEnum.setPwd))
  }

  // 返回上一步
  const backStatus = () => {
    const type = info.accountType === UserValidateMethodEnum.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone
    if (info.pwdReset) {
      store.setIsResetPassword(false)
      store.setLogOrRegister(TabsTypeLogRegisterType.log)
      store.setRegisterStatus({
        isCode: false,
        type,
      })
      return
    }
    store.setResetPasswordStatus({
      isCode: TabsResetPasswordStatusType.account,
      type,
    })
  }

  return (
    <div className={`user-retrieve-reset-password user-form-style ${styles.scoped}`}>
      <GameResetTitle backStatus={backStatus} />
      <GameResetForm
        isEmailSend={isEmailSend}
        isPhoneSend={isPhoneSend}
        loading={loading}
        setPasswordValidate={setPasswordValidate}
        password={password}
        setPasswordShow={setPasswordShow}
        handleClearPassword={handleClearPassword}
        setPassword={setPassword}
        passwordShow={passwordShow}
        onSubmit={onSubmit}
      />

      {showReset && (
        <UserPopUp
          className="user-popup user-popup-tips"
          autoFocus={false}
          visible={showReset}
          closeIcon={<Icon name="close" />}
          okText={t`user.account_security.google_07`}
          cancelText={t`user.field.reuse_48`}
          onOk={handlelRetrieve}
          onCancel={() => setShowReset(false)}
        >
          <UserPopupTipsContent
            headerIcon={<Icon name="msg" className="msg-icon-color text-auxiliary_color_01" />}
            slotContent={<p>{t`features_user_log_register_modal_component_reset_password_index_yhj8eflsro`}</p>}
          />
        </UserPopUp>
      )}

      <UserPopUp
        className="user-popup user-popup-success"
        visible={successPopup}
        closable={false}
        onCancel={handleOnSuccess}
        footer={<Button type="primary" onClick={handleOnSuccess}>{t`user.field.reuse_32`}</Button>}
      >
        <UserPopUpSuccessContent slotContent={<p>{t`user.account_security.modify_password_08`}</p>} />
      </UserPopUp>

      <UserPopUp
        className="user-popup user-popup-tips"
        visible={workOrderPopUp}
        closeIcon={<Icon name="close" hasTheme />}
        okText={t`user.safety_verification_14`}
        cancelText={t`user.field.reuse_48`}
        onOk={() => {
          link(customerServiceLinks.toString())
          setWorkOrderPopUp(false)
        }}
        onCancel={() => setWorkOrderPopUp(false)}
      >
        <UserPopupTipsContent slotContent={<p>{t`user.safety_verification_13`}</p>} />
      </UserPopUp>
    </div>
  )
}

export default UserRetrieveResetPassword
