/** 忘记密码表单组件 */
import Icon from '@/components/icon'
import { Button, Form, Input, Message, Select, Image } from '@nbit/arco'
import { UserValidateMethodEnum } from '@/constants/user'
import { t } from '@lingui/macro'
import { RetrieveValidateRules, formatPhoneNumber } from '@/features/user/utils/validate'
import { MemberMemberAreaType } from '@/typings/user'

const FormItem = Form.Item

type GameFormType = {
  /** 图片参数 */
  imageVal?: string
  /** 加载 */
  loading?: boolean
  /** 表单组件提交事件 */
  onFrom: (e) => void
  /** 手机邮箱切换参数 */
  method: string
  /** 手机号参数 */
  area: MemberMemberAreaType
  /** 下拉框点击方法函数 */
  setVisible: (e: boolean) => void
  /** 手机账号切换方法 */
  handleChoosMethod: (e: string) => void
  /** 是否显示切换邮箱或者手机号切换元素 */
  isEp?: boolean
}

function GameForm2z(props: GameFormType) {
  const { onFrom, method, area, setVisible, loading, imageVal, handleChoosMethod, isEp = true } = props
  const [form] = Form.useForm()
  const rules = RetrieveValidateRules()

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onSubmit={value => onFrom(value)}
        autoComplete="off"
        validateTrigger="onBlur"
        // onChange={handleValidateChange}
      >
        {method === UserValidateMethodEnum.email && (
          <FormItem label={t`user.safety_items_04`} field="email" rules={[rules.email]} requiredSymbol={false}>
            <Input placeholder={t`user.validate_form_02`} suffix={<div></div>} />
          </FormItem>
        )}

        {method === UserValidateMethodEnum.phone && (
          <FormItem
            label={t`user.validate_form_03`}
            field="phone"
            rules={[rules.phone]}
            requiredSymbol={false}
            className="custom-arco-form-item"
            formatter={value => formatPhoneNumber(value, area?.codeVal)}
            normalize={value => formatPhoneNumber(value, area?.codeVal, true)}
          >
            <Input
              placeholder={t`user.field.reuse_11`}
              addBefore={
                <Select
                  onClick={() => setVisible(true)}
                  style={{ width: 100 }}
                  popupVisible={false}
                  arrowIcon={<Icon name="arrow_open" hasTheme />}
                  prefix={
                    <>
                      <Image preview={false} src={imageVal} /> +{area?.codeVal}{' '}
                    </>
                  }
                />
              }
            />
          </FormItem>
        )}

        <FormItem style={{ marginBottom: 0 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            // disabled={disabled || !isAccount}
          >
            {t`user.field.reuse_23`}
          </Button>
        </FormItem>
      </Form>
      {isEp && (
        <div
          onClick={() => {
            const type =
              method === UserValidateMethodEnum.email ? UserValidateMethodEnum.phone : UserValidateMethodEnum.email
            handleChoosMethod(type)
          }}
          className="retrieve-switch-text"
        >
          {method === UserValidateMethodEnum.email
            ? t`features_user_log_register_modal_component_retrieve_index_btmarczw1_`
            : t`features_user_log_register_modal_component_retrieve_index__mquydhtmz`}
        </div>
      )}
    </div>
  )
}

export default GameForm2z
