/** 忘记密码 - 头部组件  */
import { t } from '@lingui/macro'
import { GetUserLimitSwitchEnum } from '@/constants/user'
import UserTipsInfo from '@/features/user/common/tips-info'
import Icon from '@/components/icon'
import style from './index.module.css'

function GameResetTitle2z(prods) {
  const { backStatus, popupLimit } = prods
  return (
    <div>
      <div className={style['user-retrive-title-header']}>
        <Icon name="nav_icon_back" className="retrive-icon" onClick={() => backStatus()} />
        <p>{t`user.pageContent.title_03`}</p>
      </div>
      {popupLimit?.pwdLimitSwitch === GetUserLimitSwitchEnum.enable && (
        <UserTipsInfo
          slotContent={<p>{t`features_user_log_register_modal_component_reset_password_index_gqkcmsrsv1`}</p>}
        />
      )}
    </div>
  )
}

export default GameResetTitle2z
