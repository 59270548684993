import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { useState, useEffect } from 'react'
import Link from '@/components/link'
import Icon from '@/components/icon'
import { useLayoutStore } from '@/store/layout'
import {
  ChinaAreaCodeEnum,
  UserAgreementEnum,
  LoginTypeStatusEnum,
  SignInWithEnum,
  UserVerifyTypeEnum,
  UserValidateMethodEnum,
  ThirdPartyCheckoutType,
  UserUpsAndDownsColorEnum,
} from '@/constants/user'
import { useUserStore } from '@/store/user'
import UserSearchArea from '@/features/user/common/search-area'
import UserPopUp from '@/features/user/components/popup'
import { Input, Button, Checkbox, Message } from '@nbit/arco'
import { MemberMemberAreaType } from '@/typings/user'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { TabsTypeLogRegisterType } from '@/features/user/log-register-modal/component/log-type-tabs'
import { postRegisterGoogleRequest, postRegisterAppleRequest } from '@/apis/user'
import { useCommonStore } from '@/store/common'
import styles from './index.module.css'

function MasterAccount() {
  const { localeInfo } = useCommonStore()
  const [area, setArea] = useState<MemberMemberAreaType>({
    codeVal: ChinaAreaCodeEnum.code,
    codeKey: t`features_user_personal_center_account_security_phone_index_2432`,
    remark: ChinaAreaCodeEnum.remark,
  })
  const [visible, setVisible] = useState<boolean>(false)
  const [account, setAccount] = useState<string>('')
  const [accountType, setAccountType] = useState<number>(UserVerifyTypeEnum.email)
  const [invitationValue, setInvitationValue] = useState<string>('')
  const [loginType, setLoginType] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [checkBoxStatus, setCheckBoxStatus] = useState<boolean>(false)

  const layoutStore = useLayoutStore()
  const {
    threePartyLoginData,
    thirdPartyToken,
    setUserInfo,
    setLogOrRegister,
    setRegisterStatus,
    setMemberBaseColor,
    setLogRegisterVisible,
    setThreePartyLoginList,
    setThreePartyLoginData,
    setThreePartyToken,
    setLogin,
  } = useUserStore()
  const geeTestInit = useGeeTestBind()

  const getRouterParams = () => {
    const { type, account: accountData, loginType: accountRouterType } = threePartyLoginData
    return { type, accountData, accountRouterType }
  }

  const getRouterInformation = () => {
    const { type, accountData, accountRouterType } = getRouterParams()
    accountData && setAccount(accountData)
    type && setAccountType(Number(type))
    accountRouterType && setLoginType(accountRouterType)
  }

  const handleSelectArea = (v: MemberMemberAreaType) => {
    setArea(v)
  }

  const handleLogin = async data => {
    const params = data?.userInfo
    setThreePartyToken({
      accessToken: data.token,
      refreshToken: data.refreshToken,
      accessTokenExpireTime: data.tokenExpireTime,
      refreshTokenExpireTime: data.refreshTokenExpireTime,
    })
    await setUserInfo({ ...params, ...data?.usrMemberSettingsVO })
    await setMemberBaseColor(data.usrMemberSettingsVO?.marketSetting || UserUpsAndDownsColorEnum.greenUpRedDown)

    setRegisterStatus({
      isCode: true,
      type: UserValidateMethodEnum.email,
    })
    setThreePartyLoginList({
      type: accountType,
      account: params?.email || params?.mobileNumber,
    })
    // link(`/login/set-password?account=${params?.email || params?.mobileNumber}&type=${accountType}`)
    setLoading(false)
  }

  const geeTestOnSuccess = async () => {
    let params = {
      idToken: thirdPartyToken,
      status: LoginTypeStatusEnum.registering,
      invitationCode: invitationValue,
    } as any
    if (loginType === SignInWithEnum.google) {
      const { data, isOk } = await postRegisterGoogleRequest(params)
      if (!isOk) return setLoading(false)
      data && handleLogin(data)
    } else {
      params = { ...params, type: ThirdPartyCheckoutType.apple }
      const { data, isOk } = await postRegisterAppleRequest(params)
      if (!isOk) return setLoading(false)
      data && handleLogin(data)
    }
  }

  /** 注册 */
  const onSumbitChange = async () => {
    if (!checkBoxStatus) {
      return Message.info(t`features_user_register_flow_index_2450`)
    }
    /** 极验验证 */
    setLoading(true)
    geeTestInit(geeTestOnSuccess, () => setLoading(false))
  }

  const backStatus = () => {
    setThreePartyLoginData({})
    setLogOrRegister(TabsTypeLogRegisterType.log)
  }

  useEffect(() => {
    getRouterInformation()
    const invitationCode = threePartyLoginData?.invitationCode
    invitationCode && setInvitationValue(invitationCode)
  }, [threePartyLoginData])

  return (
    <section className={styles.scoped}>
      <div className="master-account-body">
        <div className="main">
          <div className="master-account-wrap">
            <div className="master-account-content">
              <div className="safety-verification-title-header">
                <Icon name="nav_icon_back" className="retrive-icon" onClick={() => backStatus()} />
                <p>{t`features_user_login_master_account_index_u4cp0osfvd`}</p>
              </div>
              <Input type={'text'} placeholder={t`user.validate_form_02`} value={account} readOnly />
              <Input
                type={'text'}
                readOnly={!!threePartyLoginData.invitationCode}
                placeholder={t`features_user_login_master_account_index_lha4ssocv6`}
                value={invitationValue}
                onChange={setInvitationValue}
              />
              <div className="my-6">
                <Checkbox checked={checkBoxStatus} onChange={setCheckBoxStatus}>
                  {() => {
                    return (
                      <div className="service-agreement">
                        <Icon
                          className={`${checkBoxStatus ? 'select-icon' : 'unchecked-icon'} service-agreement-icon`}
                          name={
                            checkBoxStatus
                              ? localeInfo?.agreement?.selectIcon || 'icon_agree_yes'
                              : localeInfo?.agreement?.uncheckedIcon || 'icon_agree_yes'
                          }
                        />
                        <div className="text">
                          <label>
                            {t({
                              id: 'user.validate_form_09',
                              values: { 0: layoutStore.headerData?.businessName },
                            })}
                          </label>
                          <span
                            className="customize-link-style"
                            onClick={() => {
                              setLogRegisterVisible(false)
                              link(layoutStore.columnsDataByCd?.[UserAgreementEnum.termsService]?.webUrl)
                            }}
                          >{t`features_user_personal_center_account_security_manage_account_index_ulmxsiq15y`}</span>
                        </div>
                      </div>
                    )
                  }}
                </Checkbox>
              </div>
              <Button type="primary" loading={loading} className="master-account-button" onClick={onSumbitChange}>
                {t`user.field.reuse_17`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <UserPopUp
        title={<div style={{ textAlign: 'left' }}>{t`user.search_area_04`}</div>}
        className="user-popup"
        maskClosable={false}
        visible={visible}
        closeIcon={<Icon name="close" hasTheme />}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <UserSearchArea
          type="area"
          checkedValue={area?.codeVal}
          placeholderText={t`user.field.reuse_25`}
          selectArea={handleSelectArea}
        />
      </UserPopUp>
    </section>
  )
}

export default MasterAccount
