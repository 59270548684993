import { useEffect, useState } from 'react'
import { Button, Form, Input, Message, Select, Image } from '@nbit/arco'
import { t } from '@lingui/macro'
import UserSearchArea from '@/features/user/common/search-area'
import UserPopUp from '@/features/user/components/popup'
import { MemberMemberAreaType } from '@/typings/user'
import { UserValidateMethodEnum, UserVerifyTypeEnum } from '@/constants/user'
import { RetrieveValidateRules } from '@/features/user/utils/validate'
import { useUserStore } from '@/store/user'
import { IsAccountType } from '@/features/user/utils/common'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { postAccountCheckRequest } from '@/apis/user'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { TabsResetPasswordStatusType } from '@/features/user/log-register-modal/component/log-type-tabs'
import Icon from '@/components/icon'
import useAreaIp from '@/hooks/features/area-ip'
import { useRequest } from 'ahooks'
import { useLimitSwitch } from '@/hooks/features/limitSwitch'
import GameRetrieveTitle from '@/features/recreation/theme/components/game-retrieve-title'
import GameRetrieveForm from '@/features/recreation/theme/components/game-retrieve-form'
import styles from './index.module.css'

const FormItem = Form.Item

function UserRetrieve() {
  const defaultArea = useAreaIp()
  const [visible, setVisible] = useState<boolean>(false)
  const [method, setMethod] = useState<string>(UserValidateMethodEnum.email)
  // const [disabled, setDisabled] = useState<boolean>(true)
  const [area, setArea] = useState<MemberMemberAreaType>(defaultArea)
  // const verifyAccount = useRef<boolean>(false)

  const [form] = Form.useForm()
  const store = useUserStore()
  const { setCheckParams, setUserTransitionDatas } = useUserStore()
  const geeTestInit = useGeeTestBind()
  const popupLimit = useLimitSwitch()

  // const email = Form.useWatch('email', form)
  // const phone = Form.useWatch('phone', form)

  // const isAccount = email || phone
  // const info = store.userTransitionDatas

  const rules = RetrieveValidateRules()

  // const handleVerifyByAccoun = async () => {
  //   const options = {
  //     type: method === UserValidateMethodEnum.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone,
  //     account: isAccount, // 邮箱
  //     mobileCountryCode: method === UserValidateMethodEnum.phone ? area.enCode : '', // 区号
  //   }
  //   const res = await postMemberVerifyByAccoun(options)
  //   if (res.isOk) {
  //     verifyAccount.current = true
  //     form.submit()
  //     return
  //   }

  //   verifyAccount.current = false
  // }

  // const handleValidateChange = () => {
  //   form
  //     .validate()
  //     .then(() => setDisabled(false))
  //     .catch(() => setDisabled(true))
  // }

  useEffect(() => {
    setArea(defaultArea)
  }, [defaultArea])

  const handleChoosMethod = (type: string) => {
    setMethod(type)
    form.clearFields()
  }

  const handleSelectArea = (v: MemberMemberAreaType) => {
    setArea(v)
    setVisible(false)
  }

  const onSubmit = async values => {
    const isAccountType = values.email && IsAccountType(values.email)
    if (values.email && !isAccountType) {
      Message.warning(t`features/user/retrieve/index-0`)
      return
    }
    const isEmail = str => {
      // 定义邮箱的正则表达式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(str)
    }
    const checkParams = {
      accountType:
        method === UserValidateMethodEnum.email
          ? isEmail(values.email)
            ? UserVerifyTypeEnum.email
            : UserVerifyTypeEnum.uid
          : UserVerifyTypeEnum.phone,
      account: method === UserValidateMethodEnum.email ? values.email : values.phone?.replace(/\s/g, ''),
      mobileCountryCode: area.codeVal || '',
    }
    const { data, isOk } = await postAccountCheckRequest(checkParams)
    if (isOk) {
      await store.setUserTransitionDatas({
        accountType: method === UserValidateMethodEnum.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone,
        email: isAccountType === UserVerifyTypeEnum.email && values.email,
        mobileNumber: values.phone?.replace(/\s/g, ''),
        mobileCountryCode: area.codeVal,
        uid: isAccountType === UserVerifyTypeEnum.uid && values.email,
      })
      setCheckParams(checkParams)
      setUserTransitionDatas(checkParams)
      /** 极验验证 */
      geeTestInit(geeTestOnSuccess, geeTestOnError)
    }
  }

  const { run: onFrom, loading } = useRequest(onSubmit, { manual: true })

  const geeTestOnSuccess = async () => {
    const type = method === UserValidateMethodEnum.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone
    // 重置密码进入下一阶段
    store.setResetPasswordStatus({
      isCode: TabsResetPasswordStatusType.passWord,
      type,
    })
  }

  const geeTestOnError = async () => {}

  // 返回上一步
  const backStatus = () => {
    store.setIsResetPassword(false)
  }

  return (
    <div className={`user-retrive user-form-style ${styles['retrieve-form-wrap']}`}>
      <div className="user-retrive-wrap">
        {/* 头部组件 */}
        <GameRetrieveTitle backStatus={backStatus} />
        {/* 表单组件 */}
        <GameRetrieveForm
          method={method}
          onFrom={onFrom}
          area={area}
          setVisible={setVisible}
          imageVal={`${oss_area_code_image_domain_address}${area?.remark}.png`}
          loading={loading}
          handleChoosMethod={handleChoosMethod}
        />
        {/* 弹窗组件 */}
        <UserPopUp
          title={<div style={{ textAlign: 'left' }}>{t`user.search_area_04`}</div>}
          className="user-popup"
          maskClosable={false}
          visible={visible}
          closeIcon={<Icon name="close" hasTheme />}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <UserSearchArea
            type="area"
            checkedValue={area?.codeVal}
            placeholderText={t`user.field.reuse_25`}
            selectArea={handleSelectArea}
          />
        </UserPopUp>
      </div>
    </div>
  )
}

export default UserRetrieve
