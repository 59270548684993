/** 弹窗安全项验证组件 */
import React from 'react'
import { UserEnabledStateTypeEnum, UserValidateMethodEnum } from '@/constants/user'
import { t } from '@lingui/macro'
import { MemberBaseSafaVerifyBaseInfoResp, MemberVerifyAccountResp } from '@/typings/user'

type GameVerifyModeType = React.HTMLAttributes<HTMLDivElement> & {
  /** 安全项对象 */
  merchantSettings: MemberVerifyAccountResp
  /** 当前用户信息 */
  userInfo: MemberBaseSafaVerifyBaseInfoResp
  /**  邮箱手机选中值 */
  verifyMethod: string
  /** 邮箱手机切事件 */
  switchVerifyMethod: (e: string) => void
  /** 手机、邮箱验证事件 */
  handleToSecurityItem: () => void
}

function GameVerifyMode(props: GameVerifyModeType) {
  const { className, merchantSettings, userInfo, verifyMethod, switchVerifyMethod, handleToSecurityItem } = props
  return (
    <div className={className}>
      {merchantSettings?.isEmailEnable &&
        userInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
        verifyMethod !== UserValidateMethodEnum.email && (
          <div className="item">
            <label
              onClick={() => switchVerifyMethod(UserValidateMethodEnum.email)}
            >{t`user.safety_verification_09`}</label>
          </div>
        )}

      {merchantSettings?.isMobileEnable &&
        userInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
        verifyMethod !== UserValidateMethodEnum.phone && (
          <div className="item">
            <label
              onClick={() => switchVerifyMethod(UserValidateMethodEnum.phone)}
            >{t`user.safety_verification_11`}</label>
          </div>
        )}

      {merchantSettings?.isGoogleEnable &&
        userInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
        verifyMethod !== UserValidateMethodEnum.validator && (
          <div className="item">
            <label
              onClick={() => switchVerifyMethod(UserValidateMethodEnum.validator)}
            >{t`user.safety_verification_10`}</label>
          </div>
        )}

      <div className="item">
        <label onClick={handleToSecurityItem}>{t`user.safety_verification_12`}</label>
      </div>
    </div>
  )
}

export default GameVerifyMode
