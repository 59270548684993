/** 登录弹窗右边组件 */

import LogTypeTabs, {
  TabsTypeLogRegisterType,
  TabsResetPasswordStatusType,
} from '@/features/user/log-register-modal/component/log-type-tabs'
import UserRetrieve from '@/features/user/log-register-modal/component/retrieve'
import UserRetrieveResetPassword from '@/features/user/log-register-modal/component/reset-password'
import RestUserSafetyVerification from '@/features/user/log-register-modal/component/rest-safety-verification'
import UserRegisterFlow from '@/features/user/log-register-modal/component/flow'
import UserRegisterVerification from '@/features/user/log-register-modal/component/verification'
import UserLogin from '@/features/user/log-register-modal/component/user-login'
import UserSafetyVerification from '@/features/user/log-register-modal/component/safety-verification'
import MasterAccount from '@/features/user/log-register-modal/component/master-account'
import SetPassword from '@/features/user/log-register-modal/component/set-password'
import style from './index.module.css'

type LoginRightType = {
  /** 初始化弹框是注册还是登录 */
  logOrRegister: TabsTypeLogRegisterType
  /** tab 切换方法 */
  onTabsChange?: (e: TabsTypeLogRegisterType) => void
  /** 是否忘记密码 */
  isResetPassword?: boolean
  /** 登录注册弹框进入到验证邮箱，手机号验证码阶段 */
  registerStatus: {
    isCode?: boolean
    type?: string
  }
  /** 重置密码是否进入到下一步 */
  resetPasswordStatus: {
    isCode: string
    type: string
  }
}

function GameLoginRight2z(props: LoginRightType) {
  const { onTabsChange, logOrRegister, isResetPassword, registerStatus, resetPasswordStatus } = props

  return (
    <div className={style['scroll-log-register']}>
      <div className="log-register-right">
        {!registerStatus?.isCode && !isResetPassword && logOrRegister !== TabsTypeLogRegisterType.threePartyLogin && (
          <LogTypeTabs value={logOrRegister} onChange={onTabsChange} />
        )}
        <div className="log-register-right-content">
          {isResetPassword ? (
            <>
              {resetPasswordStatus.isCode === TabsResetPasswordStatusType.account && <UserRetrieve />}
              {resetPasswordStatus.isCode === TabsResetPasswordStatusType.passWord && <UserRetrieveResetPassword />}
              {resetPasswordStatus.isCode === TabsResetPasswordStatusType.safety && <RestUserSafetyVerification />}
            </>
          ) : (
            <>
              {logOrRegister === TabsTypeLogRegisterType.register && (
                <>
                  {!registerStatus?.isCode && <UserRegisterFlow />}
                  {registerStatus?.isCode && <UserRegisterVerification />}
                </>
              )}
              {logOrRegister === TabsTypeLogRegisterType.log && (
                <>
                  {!registerStatus?.isCode && <UserLogin />}
                  {registerStatus?.isCode && <UserSafetyVerification />}
                </>
              )}
              {logOrRegister === TabsTypeLogRegisterType.threePartyLogin && (
                <>
                  {!registerStatus?.isCode && <MasterAccount />}
                  {registerStatus?.isCode && <SetPassword />}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default GameLoginRight2z
