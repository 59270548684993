/** 忘记密码表单组件 */
import { Button, Form, Input, Message } from '@nbit/arco'
import { t } from '@lingui/macro'
import { FormValuesTrim } from '@/features/user/utils/common'
import { RetrieveRestPasswordRules } from '@/features/user/utils/validate'
import { MutableRefObject, Dispatch, SetStateAction } from 'react'
import { useUpdateEffect } from 'ahooks'
import Icon from '@/components/icon'
import UserPasswordValidateTips from '@/features/user/common/password-validate'

const FormItem = Form.Item

type passwordShowType = {
  newPasswordShow: boolean
  confirmPasswordShow: boolean
}

type GameResetFormTyp = {
  /** 表单点击事件 */
  onSubmit?: (e) => void
  /** 重置密码表单 type 切换对象 */
  passwordShow: passwordShowType
  /** 旧密码方法 change 改变事件 */
  setPassword?: (e: string) => void
  /** 关闭图标事件 */
  handleClearPassword: (e: string) => void
  /** 新密码方法参数 */
  setPasswordShow: (e: passwordShowType) => void
  /** 新密码参数 */
  password?: string
  /** 验证回调函数 */
  setPasswordValidate: Dispatch<SetStateAction<boolean>>
  /** 加载参数 */
  loading?: boolean
  /** 是不是邮箱 */
  isEmailSend: MutableRefObject<boolean>
  /** 是不是手机 */
  isPhoneSend: MutableRefObject<boolean>
}

function GameResetForm2z(props: GameResetFormTyp) {
  const [form] = Form.useForm()
  const {
    onSubmit,
    passwordShow,
    setPassword,
    handleClearPassword,
    setPasswordShow,
    password,
    setPasswordValidate,
    loading,
    isEmailSend,
    isPhoneSend,
  } = props

  const newPassword = Form.useWatch('newPassword', form)
  const confirmPassword = Form.useWatch('confirmPassword', form)
  const passwordRules = RetrieveRestPasswordRules(newPassword, isEmailSend, isPhoneSend)

  useUpdateEffect(() => {
    newPassword && confirmPassword && form.validate(['newPassword', 'confirmPassword'])
  }, [newPassword, confirmPassword])

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onSubmit={onSubmit}
        autoComplete="off"
        validateTrigger="onBlur"
        // onChange={handleValidateChange}
      >
        <FormItem
          label={t`features_user_retrieve_reset_password_index_2613`}
          field="newPassword"
          requiredSymbol={false}
          formatter={FormValuesTrim}
          validateTrigger="onChange"
          rules={[passwordRules.password]}
        >
          <Input
            type={passwordShow.newPasswordShow ? 'text' : 'password'}
            maxLength={32}
            onChange={setPassword}
            placeholder={t`user.validate_form_06`}
            suffix={
              <>
                {newPassword && (
                  <Icon name="del_input_box" hasTheme onClick={() => handleClearPassword('newPassword')} />
                )}

                <Icon
                  name={passwordShow.newPasswordShow ? 'eyes_open' : 'eyes_close'}
                  className="theme-default-icon"
                  onClick={() =>
                    setPasswordShow({
                      ...passwordShow,
                      newPasswordShow: !passwordShow.newPasswordShow,
                    })
                  }
                />
              </>
            }
          />
        </FormItem>

        <UserPasswordValidateTips password={FormValuesTrim(password) as string} validate={setPasswordValidate} />

        <FormItem
          label={t`user.field.reuse_12`}
          field="confirmPassword"
          requiredSymbol={false}
          formatter={FormValuesTrim}
          rules={[passwordRules.confirmPassword]}
        >
          <Input
            type={passwordShow.confirmPasswordShow ? 'text' : 'password'}
            maxLength={32}
            placeholder={t`features_user_log_register_modal_component_reset_password_index_se9g4zkexl`}
            suffix={
              <>
                {confirmPassword && (
                  <Icon name="del_input_box" hasTheme onClick={() => handleClearPassword('confirmPassword')} />
                )}

                <Icon
                  name={passwordShow.confirmPasswordShow ? 'eyes_open' : 'eyes_close'}
                  className="theme-default-icon"
                  onClick={() =>
                    setPasswordShow({
                      ...passwordShow,
                      confirmPasswordShow: !passwordShow.confirmPasswordShow,
                    })
                  }
                />
              </>
            }
          />
        </FormItem>

        {/* {info.accountType === UserVerifyTypeEnum.email && (
            <FormItem
              field="verifyCode"
              requiredSymbol={false}
              rules={[passwordRules.emailCode]}
              label={t`user.field.reuse_03`}
              extra={t({
                id: 'features_user_retrieve_reset_password_index_2444',
                values: { 0: UserInformationDesensitization(info.email) },
              })}
            >
              <Input
                type="number"
                maxLength={6}
                placeholder={t`user.field.reuse_20`}
                suffix={<UserCountDown onSend={handleSendEmailValidateCode} />}
              />
            </FormItem>
          )} */}

        {/* {info.accountType === UserVerifyTypeEnum.phone && (
            <FormItem
              field="verifyCode"
              requiredSymbol={false}
              rules={[passwordRules.phoneCode]}
              label={t`user.field.reuse_04`}
              extra={t({
                id: 'features_user_retrieve_reset_password_index_2444',
                values: { 0: `+${info.mobileCountryCode} ${UserInformationDesensitization(info.mobileNumber)}` },
              })}
            >
              <Input
                type="number"
                maxLength={6}
                placeholder={t`user.field.reuse_21`}
                suffix={<UserCountDown onSend={handleSendPhoneValidateCode} />}
              />
            </FormItem>
          )} */}

        {/* <div
            className="security"
            onClick={() => {
              store.setLogRegisterVisible(false)
              link(layoutStore.columnsDataByCd?.[UserAgreementEnum.unreceiveVerificationCode]?.webUrl)
            }}
          >
            {t`user.field.reuse_22`}
          </div> */}

        <FormItem style={{ marginBottom: 0 }}>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            // disabled={disabled || !newPassword || !confirmPassword || !verifyCode}
          >
            {t`user.field.reuse_24`}
          </Button>
        </FormItem>
      </Form>
    </div>
  )
}

export default GameResetForm2z
