import { WalletModeEnum } from '@/constants/common'
import { SettingsMenuEnum, WalletModeKey } from '@/constants/settings'
import { walletMode } from '../env'

/**
 * 是否展示钱包设置
 */
// export const showWalletSettings = () => walletMode === WalletModeEnum.all

/** todo: 暂时开放给后端使用 */
export const showWalletSettings = () => true

/**
 * 获取设置 tab 列表
 */
export const getSettingsTab = (isLogin: boolean) => {
  const showWallet = showWalletSettings()
  if (isLogin) {
    return Object.keys(SettingsMenuEnum)
      .filter(key => key !== WalletModeKey || showWallet)
      .map(key => SettingsMenuEnum[key])
  }
  return showWallet ? [SettingsMenuEnum.WalletMode] : []
}
