import GameResetForm2z from '@/features/recreation/theme/2z/game-reset-form'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function GameResetForm(props) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameResetForm2z {...props} />
    default:
      return <GameResetForm2z {...props} />
  }
}
