import GameRetrieveTitle2z from '@/features/recreation/theme/2z/game-retrieve-title'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'

export default function GameRetrieveTitle(props) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameRetrieveTitle2z {...props} />
    default:
      return <GameRetrieveTitle2z {...props} />
  }
}
