import { useState, useEffect, useMemo, useRef } from 'react'
import { Button, Form, Input, Message } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import UserCountDown from '@/features/user/components/count-down'
import FullScreenSpin from '@/features/user/components/full-screen-loading'
import {
  SignInWithEnum,
  UserVerifyTypeEnum,
  LoginTypeStatusEnum,
  UserValidateMethodEnum,
  ThirdPartyCheckoutType,
  UserEnabledStateTypeEnum,
  UserUpsAndDownsColorEnum,
  UserSendValidateCodeBusinessTypeEnum,
} from '@/constants/user'
import {
  postRegisterGoogleRequest,
  postRegisterAppleRequest,
  postMemberSafeVerifyEmailSend,
  postMemberSafeVerifyPhoneSend,
  postMemberSafeVerifyEmailCheck,
  postMemberSafeVerifyPhoneCheck,
  getMemberSafeVerifyGoogleCheck,
  postMemberLoginGenerateUserInfomation,
  postMemberQueryWorkOrderStatus,
  postMemberUniversalSecurityVerification,
  postMemberSafeResetPassword,
} from '@/apis/user'
import {
  TabsTypeLogRegisterType,
  TabsResetPasswordStatusType,
} from '@/features/user/log-register-modal/component/log-type-tabs'
import { UserInformationDesensitization } from '@/features/user/utils/common'
import { SafetyVerificationRules } from '@/features/user/utils/validate'
import { useUserStore } from '@/store/user'
import { postMemberVerifyByAccoun } from '@/apis/user'
import UserPopUpSuccessContent from '@/features/user/components/popup/content/success'
import { setToken } from '@/helper/auth'
import { usePageContext } from '@/hooks/use-page-context'
import { MemberVerifyAccountResp, MemberVerifyMemberByAccounReq } from '@/typings/user'
import { getLoginPath } from '@/helper/route'
import { useGeeTestBind } from '@/features/user/common/geetest'
import { handleLogRegisterRouter } from '@/helper/user'
import { useCustomerServiceLink } from '@/hooks/features/customer-service/index'
import { getSafetyRoutePath } from '@/helper/route/user'
import { GetUserTypeEnum } from '@/constants/user'
import Icon from '@/components/icon'
import styles from './index.module.css'

const FormItem = Form.Item
const expirationCode = [10000039]

function RestUserSafetyVerification() {
  const [verifyMethod, setVerifyMethod] = useState<string>(UserValidateMethodEnum.email)
  const [workOrderPopUp, setWorkOrderPopUp] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [spinShow, setSpinShow] = useState<boolean>(false)
  const isEmailSend = useRef<boolean>(false)
  const isPhoneSend = useRef<boolean>(false)
  const [successPopup, setSuccessPopup] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<MemberVerifyAccountResp>()
  const customerServiceLinks = useCustomerServiceLink()
  // const [disabled, setDisabled] = useState<boolean>(true)

  const store = useUserStore()
  const [form] = Form.useForm()
  const pageContext = usePageContext()
  const { resetPassword, checkParams } = useUserStore()
  const info = store.userTransitionDatas
  const geeTestInit = useGeeTestBind()

  const data = {
    type: UserSendValidateCodeBusinessTypeEnum.resetPassword,
    email: info.email || '',
    mobileCountryCode: info.mobileCountryCode || '',
    mobile: info.mobileNumber || '',
    uid: info.uid || '',
  }
  // const verifyCode = Form.useWatch('verifyCode', form)

  const { redirect, recreation } = pageContext.urlParsed.search

  const rules = SafetyVerificationRules(isEmailSend, isPhoneSend)

  const getMemberVerifyByAccount = async () => {
    const data = {
      ...info,
      type: checkParams.accountType,
    }
    const res = await postMemberVerifyByAccoun(data)
    setUserInfo(res?.data)
    const resData = res?.data
    await store.setUserTransitionDatas({ ...resData?.verifyInfo })
    switch (true) {
      case resData?.isEmailEnable && resData?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable:
        setVerifyMethod(UserValidateMethodEnum.email)
        break
      case resData?.isMobileEnable && resData?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable:
        setVerifyMethod(UserValidateMethodEnum.phone)
        break
      case resData?.isGoogleEnable && resData?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable:
        setVerifyMethod(UserValidateMethodEnum.validator)
        break
      default:
        setVerifyMethod(UserValidateMethodEnum.email)
        break
    }
  }

  useEffect(() => {
    getMemberVerifyByAccount()
  }, [])

  const handleToSecurityItem = async () => {
    const options = {
      type: info.accountType,
      account:
        info.accountType === UserVerifyTypeEnum.phone
          ? info.mobileNumber
          : info.accountType === UserVerifyTypeEnum.email
          ? info.email
          : info?.uid || undefined,
      mobileCountryCode: info.accountType === UserVerifyTypeEnum.phone ? info.mobileCountryCode : undefined,
    }
    const res = await postMemberQueryWorkOrderStatus(options)
    if (res.isOk && res.data?.isBeginManualVerifyProcess) {
      setWorkOrderPopUp(true)
      return
    }
    store.setLogRegisterVisible(false)
    link(getSafetyRoutePath(true, GetUserTypeEnum.resetPwd))
  }

  const switchVerifyMethod = (type: string) => {
    setVerifyMethod(type)
  }

  const handleSendEmailValidateCode = async () => {
    const res = await postMemberSafeVerifyEmailSend({
      type: UserSendValidateCodeBusinessTypeEnum.resetPassword,
      email: userInfo?.verifyInfo?.email as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isEmailSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleSendPhoneValidateCode = async () => {
    const res = await postMemberSafeVerifyPhoneSend({
      type: UserSendValidateCodeBusinessTypeEnum.resetPassword,
      mobileCountryCode: userInfo?.verifyInfo?.mobileCountryCd as string,
      mobile: userInfo?.verifyInfo?.mobileNumber as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isPhoneSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleLoginSuccess = async data => {
    if (data.token) {
      setToken(data)
      store.setMultipleLoginTime(data?.eventTime)
      await store.setUserInfo({ ...data?.userInfo, ...data?.usrMemberSettingsVO })
      await store.setMemberBaseColor(
        data?.usrMemberSettingsVO?.marketSetting || UserUpsAndDownsColorEnum.greenUpRedDown
      )
      store.setLogin(true)
      await store.removeUserTransitionDatas()
      Message.success(t`features/user/safety-verification/index-0`)
      if (recreation) {
        store.setLogRegisterVisible(false)
        return
      }
      const url = store.logModalRedirectUrl
      if (redirect || url) {
        link(redirect || url)
      }
      store.setLogRegisterVisible(false)
    }
  }

  const getRouterParams = () => {
    const routeParams = pageContext?.urlParsed
    return routeParams?.search?.bindType
  }

  const handleLogin = async (code: number) => {
    setLoading(true)
    // 登录过期码 10000039
    if (expirationCode.includes(code)) {
      link(getLoginPath())
      return
    }
    /** 极验验证 */
    geeTestInit(geeTestOnSuccess, geeTestOnError)
  }

  const geeTestOnSuccess = async () => {
    setLoading(false)
    onSuccessMethod()
  }

  const geeTestOnError = async () => {
    setLoading(false)
  }

  const onSuccessMethod = async () => {
    setLoading(true)
    switch (info.accountType) {
      case UserVerifyTypeEnum.email:
        resetPassword.account = data?.email || info?.account
        resetPassword.safeVerifyType = UserVerifyTypeEnum.email

        const emailRes = await postMemberSafeResetPassword(resetPassword)
        emailRes.isOk && emailRes.data?.isSuccess && setSuccessPopup(true)
        setLoading(false)
        break
      case UserVerifyTypeEnum.phone:
        resetPassword.mobileCountryCode = data.mobileCountryCode || info?.mobileCountryCode
        resetPassword.account = data?.mobile || info?.account
        resetPassword.safeVerifyType = UserVerifyTypeEnum.phone

        const mobileRes = await postMemberSafeResetPassword(resetPassword)
        mobileRes.isOk && mobileRes.data?.isSuccess && setSuccessPopup(true)
        setLoading(false)
        break

      case UserVerifyTypeEnum.uid:
        resetPassword.account = data?.uid || info?.account
        resetPassword.safeVerifyType = UserVerifyTypeEnum.uid

        const uidRes = await postMemberSafeResetPassword(resetPassword)
        uidRes.isOk && uidRes.data?.isSuccess && setSuccessPopup(true)
        setLoading(false)
        break
      default:
        break
    }
  }

  const handleOnSuccess = async () => {
    setSuccessPopup(false)
    store.setIsResetPassword(false)
    handleLogRegisterRouter(TabsTypeLogRegisterType.log, false)
  }

  const handleValidateCode = async values => {
    setLoading(true)
    switch (verifyMethod) {
      case UserValidateMethodEnum.email:
        const data = {
          operateType: UserSendValidateCodeBusinessTypeEnum.resetPassword,
          emailVerifyCode: values.verifyCode,
          account: userInfo?.verifyInfo?.email,
        }
        const emailRes = await postMemberUniversalSecurityVerification(data)
        emailRes.data?.isSuccess && handleLogin(emailRes.code as number)
        setLoading(false)
        break
      case UserValidateMethodEnum.validator:
        const googleData = {
          operateType: UserSendValidateCodeBusinessTypeEnum.resetPassword,
          googleVerifyCode: values.verifyCode,
          account: userInfo?.verifyInfo?.email || userInfo?.verifyInfo?.mobileNumber || checkParams.account,
          mobileCountryCode: userInfo?.verifyInfo?.mobileCountryCd,
        }
        const googleRes = await postMemberUniversalSecurityVerification(googleData)
        googleRes.data?.isSuccess && handleLogin(googleRes.code as number)
        setLoading(false)
        break
      case UserValidateMethodEnum.phone:
        const phoneData = {
          operateType: UserSendValidateCodeBusinessTypeEnum.resetPassword,
          mobileVerifyCode: values.verifyCode,
          account: userInfo?.verifyInfo?.mobileNumber,
          mobileCountryCode: userInfo?.verifyInfo?.mobileCountryCd,
        }
        const phoneRes = await postMemberUniversalSecurityVerification(phoneData)
        phoneRes.data?.isSuccess && handleLogin(phoneRes.code as number)
        setLoading(false)
        break
      default:
        break
    }
  }

  const onSubmit = async values => {
    setLoading(true)
    values.type = UserSendValidateCodeBusinessTypeEnum.login
    await handleValidateCode(values)
  }

  // 返回上一步
  const backStatus = () => {
    store.setResetPasswordStatus({
      isCode: TabsResetPasswordStatusType.passWord,
      type: UserValidateMethodEnum.email,
    })
  }

  const handlePaste = () => {
    navigator.clipboard.readText().then(text => {
      text && form.setFieldValue('verifyCode', text)
    })
  }

  return (
    <div className={`user-safety-verification user-form-style ${styles.scoped}`}>
      <div className="user-safety-verification-wrap">
        <div className="safety-verification-title-header">
          <Icon name="nav_icon_back" className="retrive-icon" onClick={() => backStatus()} />
          <p>{t`user.pageContent.title_06`}</p>
        </div>
        <Form
          form={form}
          layout="vertical"
          onSubmit={onSubmit}
          autoComplete="off"
          validateTrigger="onBlur"
          // onChange={handleValidateChange}
        >
          {userInfo?.isEmailEnable &&
            userInfo?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.email && (
              <FormItem
                label={t`user.field.reuse_03`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.emailCode]}
                extra={t({
                  id: 'user.universal_security_verification_01',
                  values: { 0: UserInformationDesensitization(userInfo?.verifyInfo?.email || '') },
                })}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`user.field.reuse_20`}
                  suffix={<UserCountDown onSend={handleSendEmailValidateCode} />}
                />
              </FormItem>
            )}

          {userInfo?.isMobileEnable &&
            userInfo?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.phone && (
              <FormItem
                label={t`user.field.reuse_04`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.phoneCode]}
                extra={t({
                  id: 'user.universal_security_verification_03',
                  values: {
                    0: `+${userInfo?.verifyInfo?.mobileCountryCd} ${UserInformationDesensitization(
                      userInfo?.verifyInfo?.mobileNumber || ''
                    )}`,
                  },
                })}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`user.field.reuse_21`}
                  suffix={<UserCountDown onSend={handleSendPhoneValidateCode} />}
                />
              </FormItem>
            )}

          {userInfo?.isGoogleEnable &&
            userInfo?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
            verifyMethod === UserValidateMethodEnum.validator && (
              <FormItem
                label={t`user.field.reuse_05`}
                field="verifyCode"
                requiredSymbol={false}
                rules={[rules.googleKey]}
                extra={t`user.safety_verification_06`}
              >
                <Input
                  type="number"
                  maxLength={6}
                  placeholder={t`user.safety_verification_05`}
                  suffix={
                    <span
                      className="affix"
                      onClick={() => handlePaste()}
                    >{t`features_user_log_register_modal_component_safety_verification_index_dbyangnlzf`}</span>
                  }
                />
              </FormItem>
            )}

          <div className="verify-mode">
            {userInfo?.isEmailEnable &&
              userInfo?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod !== UserValidateMethodEnum.email && (
                <div className="item">
                  <label
                    onClick={() => switchVerifyMethod(UserValidateMethodEnum.email)}
                  >{t`user.safety_verification_09`}</label>
                </div>
              )}

            {userInfo?.isMobileEnable &&
              userInfo?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod !== UserValidateMethodEnum.phone && (
                <div className="item">
                  <label
                    onClick={() => switchVerifyMethod(UserValidateMethodEnum.phone)}
                  >{t`user.safety_verification_11`}</label>
                </div>
              )}

            {userInfo?.isGoogleEnable &&
              userInfo?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod !== UserValidateMethodEnum.validator && (
                <div className="item">
                  <label
                    onClick={() => switchVerifyMethod(UserValidateMethodEnum.validator)}
                  >{t`user.safety_verification_10`}</label>
                </div>
              )}

            <div className="item">
              <label onClick={handleToSecurityItem}>{t`user.safety_verification_12`}</label>
            </div>
          </div>

          <FormItem style={{ marginBottom: 0 }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={disabled || !verifyCode}
            >
              {t`user.field.reuse_02`}
            </Button>
          </FormItem>
        </Form>
      </div>

      {workOrderPopUp && (
        <UserPopUp
          className="user-popup user-popup-tips"
          visible={workOrderPopUp}
          closeIcon={<Icon name="close" hasTheme />}
          okText={t`user.safety_verification_14`}
          cancelText={t`user.field.reuse_48`}
          onOk={() => {
            link(customerServiceLinks.toString())
            setWorkOrderPopUp(false)
          }}
          onCancel={() => setWorkOrderPopUp(false)}
        >
          <UserPopupTipsContent slotContent={<p>{t`user.safety_verification_13`}</p>} />
        </UserPopUp>
      )}

      <FullScreenSpin isShow={spinShow} />

      <UserPopUp
        className="user-popup user-popup-success"
        visible={successPopup}
        closable={false}
        maskClosable={false}
        onCancel={handleOnSuccess}
        footer={<Button type="primary" onClick={handleOnSuccess}>{t`user.field.reuse_32`}</Button>}
      >
        <UserPopUpSuccessContent
          icon={<Icon name="msg" className="mark-msg-icon" />}
          slotContent={
            <p className="ml-2">{t`features_user_log_register_modal_component_rest_safety_verification_index_opazddn4p7`}</p>
          }
        />
      </UserPopUp>
    </div>
  )
}

export default RestUserSafetyVerification
